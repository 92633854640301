import Cookies from "js-cookie";
import { LoginFormInputs } from "../pages/Login/Login";
import { useNavigate } from "react-router-dom";
import { ExtractError, setToken } from "../util";

export const useLogin = () => {
  const navigate = useNavigate();
  async function authenticate(payload: LoginFormInputs) {
    try {
      const req = await fetch(
        `${process.env.REACT_APP_ADMIN_BASE_URL}/api/users/login`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: payload.email,
            password: payload.password,
          }),
        },
      );
      const res = await req.json();
      const err = ExtractError(res);

      if (err.code === 401) {
        return "Invalid email or password";
      }

      console.log("refreshed access token");
      setToken(res.token);
      
      navigate("/home");
      return res;
    } catch {
      return "Invalid email or password";
    }
  }

  return authenticate;
};

import styles from "./DynamicPage.module.css";
import { NavBlock } from "../../components/NavBlock/NavBlock";
import { CSSProperties, Fragment } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { usePageContext, useUser } from "../../App";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

export const DynamicPage = () => {
  const pageData = usePageContext();

  if (!pageData) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayout>
      {pageData.layout.map((layout: any, index: number) => {
        if (isNav(layout)) {
          return (
            <NavItem
              key={`Nav layout ${layout.id} ${index}`}
              layout={layout}
              pageID={pageData.id}
            ></NavItem>
          );
        } else if (isContent(layout)) {
          return (
            <ContentItem
              key={`Content layout ${layout.id} ${index}`}
              rootItem={layout.content.jsonContent.root}
            ></ContentItem>
          );
        }
        return <Fragment key={`layout ${layout.id} ${index}`}></Fragment>;
      })}
      {/* can not use the below as content and nav items orders can be mixed */}
      {/* <ContentItems pageData={pageData} />
      <NavItems pageData={pageData} /> */}
    </PageLayout>
  );
};

const hasRole = (userRoles: string[], role: string[]) => {
  return (
    // temp workaround r.replace(/_/g, "-") for legacy roles in db
    userRoles.includes("admin") ||
    role.every((r) => userRoles.includes(r.replace(/_/g, "-")))
  );
};

const NavItem = ({ layout, pageID }: any) => {
  const user = useUser();
  return (
    <>
      <div className="grid-2">
        {layout.items.map((item: any, itemIndex: any) => {
          const showNoLinkErr = !item.link && user.roles.includes("admin");
          const show =
            showNoLinkErr ||
            (item.link && hasRole(user.roles, (item.link || {}).visibility));

          return (
            show && (
              <NavBlock
                key={`NavBlock layout ${layout.id} item ${itemIndex}`}
                link={item.link?.slug}
                name={item.blockName}
                icon={item.icon?.url}
                invertIconColors={item.invertIconColors}
                showNoLinkErr={showNoLinkErr}
                currentPageID={pageID}
              />
            )
          );
        })}
      </div>
    </>
  );
};

const ContentItem = ({ rootItem }: any) => {
  return (
    <>
      {rootItem.children.map((item: any, itemIndex: number) => (
        <div
          className={styles.contentItem}
          style={{
            textAlign: item.format,
            paddingLeft: `${item.indent * 3}rem`,
          }}
          key={`item ${itemIndex}`}
        >
          <ContentItemsSwitch key={`item ${itemIndex}`} item={item} />
        </div>
      ))}
    </>
  );
};

const isContent = (layout: any) => layout.__typename === "Content";
const isNav = (layout: any) => layout.__typename === "Nav";

const Paragraph = ({ item }: any) => (
  <>
    {item.children.map((paragraph: any, paragraphIndex: number) => {
      return (
        <ParagraphSwitch
          key={`paragraph ${paragraphIndex}`}
          style={{
            ...formatMap[paragraph.format],
            // ...convertStyleStringToObject(paragraph.style),
          }}
          paragraph={paragraph}
        />
      );
    })}
  </>
);

const List = ({ item }: any) => (
  <ListTypeSwitch listType={item.listType} className={styles.list}>
    {item.children.map((listItem: any, listItemIndex: number) =>
      listItem.children.map(
        (listItemChild: any, listItemChildIndex: number) => (
          <li
            className={styles.listItem}
            key={`listItemChild ${listItemChildIndex}`}
          >
            {listItemChild.text}
          </li>
        ),
      ),
    )}
  </ListTypeSwitch>
);

const Heading = ({ item, Tag = "h1" }: any) => (
  <Tag className={styles.heading}>
    {item.children.map((heading: any, headingIndex: number) => (
      <Fragment key={`heading ${headingIndex}`}>{heading.text}</Fragment>
    ))}
  </Tag>
);

const Table = ({ item }: any) => (
  <table
    className={clsx(
      styles.table,
      tableRowsAreNumbered(item) && styles.tableWithRowNumbers,
      tableHasHeader(item) && styles.tableWithHeader,
    )}
  >
    <tbody>
      {item.children.map((tableRow: any, tableRowIndex: number) => (
        <tr key={`tableRow ${tableRowIndex}`}>
          {tableRow.children.map((tableCell: any, tableCellIndex: number) => (
            <td key={`tableCell ${tableCellIndex}`}>
              <ContentItem rootItem={tableCell} />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const Link = ({ item }: any) => {
  const attr = item.attributes || {};
  if (
    attr.linkType === "internal" &&
    attr.doc &&
    attr.doc.data &&
    attr.doc.data.slug
  ) {
    return (
      <NavLink
        className={styles.autolink}
        to={`/${attr.doc.data.slug}`}
        target={attr.newTab ? `__blank` : ""}
      >
        {item.children.map((autolink: any, autolinkIndex: number) => (
          <Fragment key={`autolink ${autolinkIndex}`}>{autolink.text}</Fragment>
        ))}
      </NavLink>
    );
  }

  let url = (attr.url || "").replace("https://www-old.quantumlifeza.com/", "/");

  // if it's internal link, use NavLink
  const internalLink = url.match(/^(http|https):\/\//) !== null;
  if (internalLink) {
    return (
      <NavLink
        className={styles.autolink}
        to={url}
        target={attr.newTab ? `__blank` : ""}
      >
        {item.children.map((autolink: any, autolinkIndex: number) => (
          <Fragment key={`autolink ${autolinkIndex}`}>{autolink.text}</Fragment>
        ))}
      </NavLink>
    );
  }

  return (
    <a
      className={styles.autolink}
      href={url}
      target={attr.newTab ? `__blank` : ""}
    >
      {item.children.map((autolink: any, autolinkIndex: number) => (
        <Fragment key={`autolink ${autolinkIndex}`}>{autolink.text}</Fragment>
      ))}
    </a>
  );
};

const ParagraphSwitch = ({ style, paragraph }: any) => {
  switch (paragraph.type) {
    case "text":
      return (
        <span style={style} className={styles.text}>
          {paragraph.text}
        </span>
      );
    case "autolink":
      return (
        <a className={styles.autolink} href={paragraph.attributes.url}>
          {paragraph.children.map((autolink: any, autolinkIndex: number) => (
            <Fragment key={`autolink ${autolinkIndex}`}>
              {autolink.text}
            </Fragment>
          ))}
        </a>
      );
    case "upload":
      return (
        <img
          className={styles.upload}
          src={paragraph.data.url}
          alt={paragraph.data.alt}
        />
      );
    case "link":
      return <Link item={paragraph} />;
    case "linebreak":
      return <br />;
    default:
      return (
        <p style={{ color: "red" }}>
          {`Unknown PG content type (${paragraph.type}): ${JSON.stringify(
            paragraph,
            null,
            2,
          )}`}
        </p>
      );

    // default:
    //   return <></>;
  }
};

const ContentItemsSwitch = ({ item }: any) => {
  switch (item.type) {
    case "paragraph":
      return <Paragraph item={item} />;
    case "list":
      return <List item={item} />;
    case "heading":
      return <Heading Tag={item.tag} item={item} />;
    case "table":
      return <Table item={item} />;
    case "link":
      return <Link item={item} />;
    case "linebreak":
      return <br />;
    default:
      return (
        <p style={{ color: "red" }}>
          {`Unknown CS content type: ${JSON.stringify(item, null, 2)}`}
        </p>
      );
  }
};

const formatMap: Record<number, CSSProperties> = {
  4: {
    textDecoration: "line-through",
  },
  32: {
    verticalAlign: "sub",
    fontSize: "0.8rem",
  },
  8: {
    textDecoration: "underline",
  },
  2: {
    fontStyle: "italic",
  },
  1: {
    fontWeight: "bold",
  },
  64: {
    verticalAlign: "super",
    fontSize: "0.8rem",
  },
};

function convertStyleStringToObject(styleString: string): CSSProperties {
  if (styleString === "" || !styleString) return {};
  const styleObject: Record<string, string> = {};
  const styles = styleString.split(";").filter((style) => style.trim() !== "");

  styles.forEach((style) => {
    const [key, value] = style.split(":");
    if (key && value) {
      const formattedKey = key
        .trim()
        .replace(/-./g, (match) => match[1].toUpperCase());
      styleObject[formattedKey] = value.trim();
    }
  });

  return styleObject;
}

const ListTypeSwitch = ({ listType, children, className }: any) => {
  switch (listType) {
    case "bullet":
      return <ul className={className}>{children}</ul>;
    case "number":
      return <ol className={className}>{children}</ol>;
    case "check":
      return <ul className={className}>{children}</ul>;
    default:
      return <Fragment></Fragment>;
  }
};

const findText = (item: any): string => {
  if (!item) return "";

  if (item.type !== "text" && item.children?.[0])
    return findText(item.children[0]);

  return item.text || "";
};

const numbers = "0123456789";
const tableRowsAreNumbered = (table: any) => {
  if (!table.children || !table.children[0] || !table.children[0].children)
    return false;

  const firstColumn =
    table.children
      .map((row: any) => findText(row?.children[0]))
      ?.join("")
      ?.replace(/\s/g, "")
      ?.substr(0, 9) || "";

  return numbers.indexOf(firstColumn) !== -1;
};

const alphabet = "abcdefghijklmnopqrstuvwxyz";
const tableHasHeader = (table: any) => {
  // if (!table.children || !table.children[0] || !table.children[0].children)
  //   return false;
  // const rows = table.children;
  // const firstRow =
  //   rows[0].children
  //     .map((cell: any) =>
  //       findText(cell)
  //         .replace(/column/i, "")
  //         .replace(/\s/g, ""),
  //     )
  //     ?.join("")
  //     ?.replace(/\s/g, "")
  //     ?.toLowerCase() || "";

  // return alphabet.indexOf(firstRow) !== -1;
  return true;
};

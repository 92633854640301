import Cookies from "js-cookie";
import { useInterval } from "react-use";
import { fromUnixTime, isWithinInterval, addMinutes } from "date-fns";
import {
  ExtractError,
  clearToken,
  debounceSessionFunction,
  setToken,
} from "../util";
import { useNavigate } from "react-router-dom";

export const useRefreshToken = () => {
  const navigate = useNavigate();
  const refreshAccessToken = async () => {
    // do not run this function more than once in 5 min
    if (debounceSessionFunction("__last_token_refresh__", 1000 * 60 * 5)) {
      return;
    }

    try {
      const token = Cookies.get("jwt");

      const req = await fetch(
        `${process.env.REACT_APP_ADMIN_BASE_URL}/api/users/refresh-token`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        },
      );
      const res = await req.json();

      if (req.status === 200) {
        return setToken(res.refreshedToken);
      }

      // handle errors
      const err = ExtractError(res);

      // explicitly log out if token refresh failed due to 401 or 403 - means it expired
      if (err.code === 401 || err.code === 403) {
        console.log("refresh token failed, logging out");
        clearToken();
        navigate("/login");
        return;
      }

      console.log("refresh token failed", err);
    } catch {
      return "Could not refresh token, please check your internet connection.";
    }
  };

  const checkRefreshToken = () => {
    const token = Cookies.get("jwt");
    if (!token) {
      console.log("no token found, skipping refresh");
      return;
    }

    refreshAccessToken();
  };

  checkRefreshToken(); // check now
  useInterval(checkRefreshToken, 1000 * 60); // Check every 5 minutes
};

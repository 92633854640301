import { NavLink } from "react-router-dom";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import styles from "./ErrorMessage.module.css";
import { usePageContext } from "../../App";

export const ErrorMessage = () => {
  const pageData = usePageContext();

  return (
    <PageLayout>
      <div className={styles.errorMessage}>
        <p>Sorry, there was en error loading this page</p>
        <NavLink to="/home" className={styles.link}>
          Home
        </NavLink>
      </div>
    </PageLayout>
  );
};

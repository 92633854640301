import Cookies from "js-cookie";

interface APIError {
  code: number;
  msg: string;
}

export interface JWTClaims {
  email: string;
  id: string;
  collection: string;
  name: string;
  roles: string[];
  iat: number;
  exp: number;
}

export interface User {
  id: string;
  name: string;
  email: string;
  roles: string[];
}

const nullUser: User = {
  id: "",
  name: "",
  email: "",
  roles: [],
};

const HTTP_ERROR: { [key: number]: string } = {
  200: "OK",
  400: "Bad request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not found",
  500: "Internal server error",
};

export function ExtractError(httpRes: any): APIError {
  const res = { code: httpRes.status, msg: '' };

  // default error message
  res.msg = HTTP_ERROR[httpRes.status] || '';

  // extract error message from response
  if (httpRes.errors && Array.isArray(httpRes.errors) && httpRes.errors.length > 0) {
    res.msg = httpRes.errors[0].message;
  }

  return res;
}

function parseJwt(token: string): JWTClaims | null {
  try {
    const base64token = token.split(".")[1];
    const decoded = atob(base64token);
    return JSON.parse(decoded) as JWTClaims;
  } catch (e) {
    console.log("Invalid token");
    return null;
  }
}

export function clearToken() {
  Cookies.remove("jwt");
}

export function getToken() {
  return Cookies.get("jwt") || null;
}

export function setToken(token: string) {
  try {
    const currentToken = getToken();
    if (currentToken === token) {
      console.log("Token already set");
      return;
    }

    const parsed = parseJwt(token);
    if (!parsed) {
      console.log("Invalid token");
      return;
    }

    console.log("New token expiry", new Date(parsed.exp * 1000));
    Cookies.set("jwt", token, { expires: new Date(parsed.exp * 1000) });
  } catch (e) {
    console.log("Invalid token", e);
    return;
  }
}

export function getUser(): User {
  const token = getToken();
  if (!token) {
    return nullUser;
  }

  const parsed = parseJwt(token);
  if (!parsed) {
    return nullUser;
  }

  return {
    id: parsed.id,
    name: parsed.name,
    email: parsed.email,
    roles: parsed.roles,
  }
}

export const UnixTS = () => Math.floor((new Date).getTime() / 1000)

// debounceSessionFunction is a function that will only run once in a given time frame
// it uses localStorage to store the last execution time
// key is the key to store the last execution time
// delay_ms is the time frame in milliseconds
// fn is the function to run
// returns true if the function was skipped, false otherwise
export function debounceSessionFunction(key: string, delay_ms: number, fn?: Function) {
  const lastExec = Number(localStorage.getItem(key)) || 0;
  const now = UnixTS();

  if (now - lastExec < (delay_ms / 1000)) {
    return true;
  }

  localStorage.setItem(key, now.toString());

  if (fn) fn();
  return false;
}
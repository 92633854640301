import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { DynamicPage } from "./pages/DynamicPage/DynamicPage";
import Cookies from "js-cookie";
import Login from "./pages/Login/Login";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";
import { getToken } from "./util";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        loader: () => {
          const token = getToken();
          return redirect(token ? "/home" : "/login");
        },
      },
      {
        path: "/404",
        element: <PageNotFound />,
      },
      {
        path: "/logout",
        loader: () => {
          Cookies.remove("jwt");
          return redirect("/login");
        },
      },
      {
        path: "/login",
        element: <Login />,
        loader: () => {
          const token = getToken();
          if (token) {
            return redirect("/home");
          }
          return null;
        },
      },
      {
        path: "/:slug/*",
        element: <DynamicPage />,
        errorElement: <ErrorMessage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.css";
import { RiLoaderFill } from "react-icons/ri";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  loading?: "true" | "false";
};

export const Button = (props: ButtonProps) => {
  return (
    <button {...props} className={styles.button}>
      {props.loading === "true" ? (
        <RiLoaderFill className={styles.loader} />
      ) : (
        props.children
      )}
    </button>
  );
};

import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
const QUERY = `
query Page($slug: String, $draft: Boolean) {
  Pages(where: { slug: { equals: $slug } }, limit: 1, draft: $draft) {
    docs {
      id
      slug
      visibility
      title
      layout {
        __typename
        ... on Content {
          id
          blockName
          content(depth: 50)
        }
        ... on Nav {
          id
          __typename
          items {
            ... on NavItem {
              id
              __typename
              blockName
              link {
                title
                slug
                visibility
              }
              icon {
                alt
                url
              }
              invertIconColors
            }
          }
        }
      }
      updatedAt
      createdAt
      _status
    }
  }
}
`;
export const useGetPage = (): any => {
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();
  let params = useParams();
  let slug = `${params.slug}/${params["*"]}`;
  if (slug[slug.length - 1] === "/") {
    slug = slug.slice(0, -1);
  }
  const getPage = useCallback(async () => {
    try {
      const token = Cookies.get("jwt");
      const req = await fetch(
        `${process.env.REACT_APP_ADMIN_BASE_URL}/api/graphql`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
          body: JSON.stringify({
            query: QUERY,
            variables: {
              slug,
              draft: false,
            },
          }),
        },
      );
      const res = await req.json();
      if (res.errors) {
        console.log("navigate to login");
        return navigate("/login");
      } else {
        if (res.data.Pages.docs.length === 0) {
          return navigate("/404");
        }
        setPageData(res.data.Pages.docs[0]);
      }
    } catch (err) {
      console.log({ err });
    }
  }, [navigate, slug]);
  useEffect(() => {
    getPage();
  }, [slug, getPage]);
  return pageData;
};

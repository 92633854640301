import { NavLink } from "react-router-dom";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import styles from "./PageNotFound.module.css";
import { Button } from "../../components/Button/Button";

export const PageNotFound = () => {
  return (
    <PageLayout>
      <div className={styles.pageNotFound}>
        <h1>That page doesn't exist</h1>
        <p>Sorry, the page you where looking for could not be found</p>
        <NavLink to="/home" className={styles.link}>
          <Button>Home</Button>
        </NavLink>
      </div>
    </PageLayout>
  );
};

import { NavLink } from "react-router-dom";
import { Sheet } from "../Sheet/Sheet";
import styles from "./NavBlock.module.css";
import { Button } from "../../components/Button/Button";

export const NavBlock = ({
  name,
  icon,
  link,
  invertIconColors,
  showNoLinkErr,
  currentPageID,
}: any) => {
  const fixPageNav = (e: any) => {
    e.preventDefault();

    window.open(
      `${process.env.REACT_APP_ADMIN_BASE_URL}/admin/collections/pages/${currentPageID}`,
      "_blank",
    );
  };

  return (
    <NavLink to={`/${link || "404"}`}>
      <Sheet className={styles.NavBlock}>
        {icon && (
          <img
            src={icon}
            alt={name}
            className={`${styles.NavIcon} ${
              invertIconColors ? styles.invert : ""
            }`}
          />
        )}
        <p>{name}</p>
        {showNoLinkErr && (
          <>
            <p className={`${styles.errorMessage}`}>
              ADMIN: Nav item has no link <br />
            </p>
            <Button onClick={fixPageNav}>FIX Now</Button>
          </>
        )}
      </Sheet>
    </NavLink>
  );
};

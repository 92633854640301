import { Outlet } from "react-router-dom";
import { useGetPage } from "./hooks/useGetPage";
import { createContext, useContext } from "react";
import { useRefreshToken } from "./hooks/useRefreshToken";
import { ScrollRestoration } from "react-router-dom";
import { getUser } from "./util";

const PageContext = createContext({} as any);

function App() {
  const pageData = useGetPage();
  useRefreshToken();

  return (
    <PageContext.Provider value={pageData}>
      <Outlet />
      <ScrollRestoration />
    </PageContext.Provider>
  );
}

export default App;

export const usePageContext = () => {
  return useContext(PageContext);
};

export const useUser = () => getUser() || { roles: JSON.parse('[]') };

import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { IoHomeOutline } from "react-icons/io5";
import { IoClipboardOutline } from "react-icons/io5";
import { IoLogOutOutline } from "react-icons/io5";
import clsx from "clsx";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import { HiMenu } from "react-icons/hi";
const IMAGE_SCALE = 0.25;

export const Header = ({ className }: { className?: string }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <header
      className={clsx(
        styles.header,
        className,
        sidebarOpen && styles.sidebarOpen,
      )}
    >
      <Logo />
      <div className={styles.sidebarCloseButton} onClick={toggleSidebar}>
        <HiMenu />
      </div>
      <div className={styles.sidebar}>
        <div className={styles.sidebarHeader}>
          <div className={styles.sidebarCloseButton} onClick={toggleSidebar}>
            <IoMdClose />
          </div>
        </div>
        <div
          className={styles.sidebarContent}
          onClick={() => setSidebarOpen(false)}
        >
          <Link className={styles.link} to="/home">
            <IoHomeOutline />
            <span>Home</span>
          </Link>
          <Link className={styles.link} to="/trauma-frequencies">
            <IoClipboardOutline />
            <span>Trauma Frequencies</span>
          </Link>
          <Link className={styles.link} to="/logout">
            <IoLogOutOutline />
            <span>Logout</span>
          </Link>
        </div>
      </div>
      <div className={styles.backdrop} onClick={() => setSidebarOpen(false)} />
    </header>
  );
};

export const Logo = () => (
  <img
    src="/white-logo.png"
    alt="logo"
    width={326 * IMAGE_SCALE}
    height={200 * IMAGE_SCALE}
  />
);

import { usePageContext } from "../../App";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import styles from "./PageLayout.module.css";

export const PageLayout = ({ children }: any) => {
  const pageData = usePageContext();
  return (
    <div className={styles.app}>
      <Header className={styles.header} />
      <main className={styles.main}>
        <div className={styles.content}>
          <Breadcrumbs slug={pageData?.slug || "home"} />
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};

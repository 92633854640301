import { NavLink } from "react-router-dom";
import styles from "./Footer.module.css";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        <NavLink to="/home/acceptable-use-policy">
          Acceptable Use Policy
        </NavLink>
        <NavLink to="/home/terms-of-use-policy-general">
          Terms Of Use Policy
        </NavLink>
        <NavLink to="/home/privacy-policy">Privacy Policy</NavLink>
      </div>
      <div className={styles.copyright}>
        Copyright ©️ {currentYear} Healthy Vibes Pty Ltd T/A The Quantum
        Academy.
      </div>
    </footer>
  );
};

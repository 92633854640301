import React from "react";
import styles from "./Sheet.module.css";

type SheetProps = React.HTMLAttributes<HTMLDivElement> & {};

export const Sheet = (props: SheetProps) => {
  return (
    <div {...props} className={styles.sheet + " " + props.className}>
      {props.children}
    </div>
  );
};

import styles from "./Login.module.css";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { Sheet } from "../../components/Sheet/Sheet";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLogin } from "../../hooks/useLogin";
import { useState } from "react";

const IMAGE_SCALE = 0.68;

export type LoginFormInputs = {
  email: string;
  password: string;
  msg: string;
};

export default function Login() {
  const authenticate = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  const [errorMsg, setErrorMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setIsLoading(true);
    setErrorMsg(await authenticate(data));
    setIsLoading(false);
  };

  return (
    <div className={styles.main}>
      <Sheet>
        <img
          src="/white-logo.png"
          alt="logo"
          width={326 * IMAGE_SCALE}
          height={200 * IMAGE_SCALE}
        />
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="email"
            placeholder="Email"
            required
            {...register("email", { required: true })}
          />
          {errors.email && (
            <p className={styles.errorMessage}>Email is required</p>
          )}
          <Input
            type="password"
            placeholder="Password"
            required
            {...register("password", { required: true })}
          />
          {errors.password && (
            <p className={styles.errorMessage}>Password is required</p>
          )}
          <Button
            loading={isLoading ? "true" : "false"}
            aria-disabled={isLoading}
            type="submit"
          >
            Login
          </Button>

          {errorMsg && <p className={styles.errorMessage}>{errorMsg}</p>}

          {/* TODO: add forgot password */}
        </form>
      </Sheet>
    </div>
  );
}

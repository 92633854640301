import { NavLink } from "react-router-dom";
import styles from "./Breadcrumbs.module.css";
import { MdChevronRight } from "react-icons/md";
import { MdChevronLeft } from "react-icons/md";
import { useMedia } from "react-use";

import { sentenceCase } from "change-case";
import { Fragment } from "react";

export const Breadcrumbs = ({ slug }: any) => {
  const isMobile = useMedia("(max-width: 768px)");

  if (!slug) {
    return <></>;
  }

  const crumbs = slug.split("/").map((p: string, i: number, a: string[]) => {
    return {
      name: p,
      link: "/" + a.slice(0, i + 1).join("/"),
    };
  });

  const secondLastCrumb = crumbs[crumbs.length - 2];
  const firstCrumb = crumbs[0];
  const fourthLastCrumb = crumbs[crumbs.length - 3];
  const lastThreeCrumbs = crumbs.slice(-3);

  if (isMobile && crumbs.length > 2)
    return (
      <div className={styles.breadcrumbs}>
        <NavLink to={secondLastCrumb.link}>
          <MdChevronLeft />
          {sentenceCase(secondLastCrumb.name)}
        </NavLink>
      </div>
    );

  if (crumbs.length > 5) {
    return (
      <div className={styles.breadcrumbs}>
        <NavLink to={firstCrumb.link}>{sentenceCase(firstCrumb.name)}</NavLink>
        <MdChevronRight />
        <NavLink to={fourthLastCrumb.link}>...</NavLink>
        <MdChevronRight />
        {lastThreeCrumbs.map((c: any, i: number) => (
          <Fragment key={"breadcrumb" + i}>
            <NavLink to={c.link}>{sentenceCase(c.name)}</NavLink>
            <>{i < crumbs.length - 4 && <MdChevronRight />}</>
          </Fragment>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.breadcrumbs}>
      {crumbs.map((c: any, i: number) => (
        <Fragment key={"breadcrumb" + i}>
          <NavLink to={c.link}>{sentenceCase(c.name)}</NavLink>
          <>{i < crumbs.length - 1 && <MdChevronRight />}</>
        </Fragment>
      ))}
    </div>
  );
};

import { ForwardedRef, InputHTMLAttributes, Ref, forwardRef } from "react";
import styles from "./Input.module.css";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  ref?: Ref<HTMLInputElement>;
};

export const Input = forwardRef(function (
  props: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return <input ref={ref} {...props} className={styles.input} />;
});
